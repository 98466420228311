var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-bottom-sheet-vue2',{ref:"paymentMethodBottomSheet",attrs:{"custom-class":"payment-method-bottom-sheet","z-index":1000},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"header"},[_vm._v(_vm._s(_vm.$t("payermax.choose_payment_method")))])]},proxy:true},{key:"default",fn:function(){return [_c('div',{staticClass:"main-container",attrs:{"id":"main-container"}},_vm._l((_vm.paymentTypes),function(paymentType,index){return _c('div',{key:index,class:[
            "product-container",
            {
              'product-container-selected':
                _vm.selectedPayment !== null &&
                paymentType.name === _vm.selectedPayment.name,
            } ],on:{"click":function($event){return _vm.selectPaymentMethod(paymentType)}}},[_c('div',{staticClass:"product-inner-container"},[_c('div',{staticClass:"payment-type"},[_c('div',{staticClass:"payment-type-icon"},[_c('img',{attrs:{"src":paymentType.icon_url,"alt":"payment-type-icon"}})]),_c('div',{staticClass:"name-container"},[(
                    paymentType.payment_price !== null &&
                    (paymentType.payment_price.discount ||
                      paymentType.payment_price.coin_increase_percentage)
                  )?_c('div',{staticClass:"discount-badge"},[(paymentType.payment_price.coin_increase_percentage)?_c('div',{staticClass:"discount-text"},[_vm._v(" +% "+_vm._s(paymentType.payment_price.coin_increase_percentage)+" ")]):_c('div',{staticClass:"discount-text"},[_vm._v(" % "+_vm._s(paymentType.payment_price.discount)+" "+_vm._s(_vm.$t("labels.discount"))+" ")])]):_vm._e(),_c('div',{staticClass:"name"},[_vm._v(_vm._s(paymentType.name))])])]),(paymentType.payment_provider !== _vm.PaymentProvider.DEALER)?_c('div',{staticClass:"coin-info"},[_c('div',{staticClass:"coin-icon"},[_c('img',{attrs:{"src":require("@/assets/img/coin.svg"),"alt":"coin"}})]),_c('div',{staticClass:"coin-amount"},[(
                    paymentType.payment_price !== null &&
                    paymentType.payment_price.overriden_coin_amount
                  )?_c('div',[(_vm.showCoinIncrease)?_c('div',{staticClass:"initial-coin-amount"},[_vm._v(" "+_vm._s(_vm.calculateInitialCoinAmount( paymentType.payment_price.overriden_coin_amount, paymentType.payment_price.coin_increase_percentage ))+" ")]):_vm._e(),_c('div',{staticClass:"amount"},[_vm._v(" "+_vm._s(paymentType.payment_price.overriden_coin_amount)+" ")])]):_c('div',{staticClass:"amount"},[_vm._v(" "+_vm._s(_vm.product.amount)+" ")])])]):_c('div',{staticClass:"dealer-button"},[_c('img',{attrs:{"src":require('@/assets/img/greater-icon.svg')}})])])])}),0),_c('div',{staticClass:"pay-button-container"},[_c('div',{staticClass:"pay-button",on:{"click":function($event){return _vm.proceedPayment(_vm.selectedPayment)}}},[(
              _vm.selectedPayment !== null &&
              _vm.selectedPayment.payment_provider !== _vm.PaymentProvider.DEALER
            )?_c('div',[_vm._v(" "+_vm._s(_vm.currency)+" "+_vm._s(_vm.selectedPayment.payment_price.price)+" "+_vm._s(_vm.$t("labels.do_payment"))+" ")]):_vm._e()])]),_c('div',{staticClass:"payment-method-footer"},[_c('button',{staticClass:"customer-service",on:{"click":_vm.openCustomerService}},[_c('img',{attrs:{"src":require("@/assets/img/headphone-icon-new.svg"),"alt":"Customer Service"}}),_c('span',[_vm._v(_vm._s(_vm.$t("payermax.customer_service")))])]),_c('div',{staticClass:"privacy-link"},[_c('span',{staticClass:"link",on:{"click":function($event){return _vm.openLink(_vm.privacyPolicyLink)}}},[_vm._v(_vm._s(_vm.$t("menu.terms_of_service")))]),_c('span',{staticClass:"link-seperator"},[_vm._v("&")]),_c('span',{staticClass:"link",on:{"click":function($event){return _vm.openLink(_vm.termsOfServiceLink)}}},[_vm._v(_vm._s(_vm.$t("menu.privacy_policy")))])])]),_c('v-overlay',{attrs:{"color":"#fff","value":_vm.showFullPageLoading}},[_c('v-progress-circular',{attrs:{"color":"#333","size":50,"indeterminate":""}})],1)]},proxy:true}])}),(_vm.savedCardsData !== null)?_c('SavedCardsBottomSheet',{ref:"savedCardsBottomSheet",attrs:{"data":_vm.savedCardsData,"show-full-page-loading":_vm.savedCardLoading,"show-save-card-permission":_vm.selectedPayment.payment_provider === _vm.PaymentProvider.TRUSTPAY,"show-add-card":(_vm.selectedPayment.payment_provider === _vm.PaymentProvider.TRUSTPAY &&
        _vm.savedCardsData.cards.length < 5) ||
      _vm.selectedPayment.payment_provider === _vm.PaymentProvider.PAYERMAX},on:{"add-card":_vm.onAddCard,"delete-card":_vm.onDeleteCard,"pay":_vm.onSavedCardPay}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }